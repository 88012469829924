.album-slider
	position relative

	&__desktop
		@media tablet
			display none

	&__tablet
		position relative
		padding-top 56.25%
		display none

		@media tablet
			display block

		@media mobile
			height 378px

	&__tablet-inner
		position absolute
		top 0
		left 0
		size 100%

	&__frame
		position relative
		margin-bottom 30px
		overflow hidden
		height 532px

	&__frame-inner
		position absolute
		top 0
		left 0
		display none
		size 100%

		&_active
			display block
			animation albumShow .3s ease-out

	&__frame-image
		size 100%
		background-position center center
		background-size cover

	&__img
		size 100%
		background-position center center
		background-size cover

	&__icon
		position absolute
		right 11px
		bottom 12px
		width 22px
		height 14px
		color white

	&__wrapper
		flex-shrink 0

	&__slide
		position relative
		display flex
		justify-content center
		overflow hidden
		size 166px
		cursor pointer
		transition opacity trDur trFunc
		will-change opacity 

		&:hover
			opacity .8

	&__controls
		position absolute
		right -73px
		left -73px
		bottom 65px
		display flex
		justify-content space-between
		user-select none

	&__pagination
		display none

	&__button
		position static
		display block
		size 44px
		cursor pointer
		background url('/images/arrow-button.svg') center center no-repeat
		border none

		&:hover
			opacity .6

		&_next
			transform rotate(180deg)

		&[aria-disabled='true']
			cursor default
			opacity .3

			&:hover
				opacity .3

@keyframes albumShow
	from
		opacity 0
	to
		opacity 1
