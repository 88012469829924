import('helpers/preloader')
import('sprites/*')
import('helpers/svg-size')
import('helpers/variables')
import('helpers/mixins')
import('helpers/fonts')
import('helpers/optimize')
import('helpers/classes')
import('helpers/typo')

@import 'vendors/swiper.css'
@import 'vendors/fancybox.css'

.no-scroll
	overflow hidden

body
	display flex
	flex-direction column
	min-height 100vh
	font-family Avenir
	font-size 22px
	font-weight 400
	line-height 1.45
	letter-spacing -.2px
	color black

i
	font-style italic

import('../blocks/**/*')
