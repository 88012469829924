.profile-offer-single
	&__return-link
		margin-top 20px
		white-space nowrap

	&__text
		@extends .text22Regular
		margin-top 55px
		margin-bottom 70px

		@media tablet
			margin-top 20px
			margin-bottom 50px

		@media mobile
			font-size 18px
			margin-top 15px
			margin-bottom 40px

		&::first-letter
			@extends .capital-letter
