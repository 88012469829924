.four-pictures-block
	display flex

	&__big
		width 554px
		height 555px
		background-position center center
		background-size cover

		@media tablet
			width 344px
			height 343px

	&__images-wrapper
		margin-left 30px
		display flex
		flex-wrap wrap
		width 554px

		@media tablet
			margin-left 18px
			width 344px

	&__medium
		margin-bottom 30px
		flex 1 1 100%
		width 554px
		height 262px
		background-position center center
		background-size cover

		@media tablet
			margin-bottom 18px
			width 344px
			height 162px

	&__small
		width 262px
		height 262px
		background-position center center
		background-size cover

		@media tablet
			width 163px
			height 162px

		&:last-child
			margin-left 30px

			@media tablet
				margin-left 18px
