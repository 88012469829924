.index
	display flex
	justify-content center
	align-items center
	width 100%
	min-height 100vh
	background-color lightgrey

	&__pages
		margin-y 60px
		padding 20px 40px
		background-color white
		border-radius 10px

	&__title
		margin-y 15px
		font-size 24px

	&__link
		margin-bottom 5px
		padding-left 15px
		display block
		font-size 20px
		text-decoration none
		border-radius 6px
