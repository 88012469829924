.partners-page
	padding-top 40px
	text-align center

	@media tablet
		padding-top 55px

	@media mobile
		padding-top 20px

	&__partners-list
		margin-x -15px
		margin-top 70px
		padding-bottom 80px
		display flex
		flex-wrap wrap

		@media tablet
			margin-x 0
			margin-top 53px
			padding-bottom 70px

		@media mobile
			margin-top 35px
			padding-bottom 6px

	&__partner
		padding-x 15px
		padding-bottom 30px
		width 33.3333%
		min-height 530px

		@media tablet
			margin-x auto
			padding-x 0
			width 100%
			max-width 708px

		@media mobile
			padding-bottom 10px
			min-height 510px
