.slider-holes
	position relative
	padding-right 98px
	padding-left 125px
	size 100%

	@media tablet
		padding-x 0

	&__slider
		overflow hidden

	&__slide
		display flex
		justify-content space-around
		align-items center

		@media tablet
			flex-direction column

	&__button-controls
		@extends .defaultHover
		position absolute
		top 50%
		margin 0
		padding 0
		width 44px
		height 44px
		background url('/images/arrowLeft.svg') 50% 50% no-repeat
		background-color rgba(grayLight, .25)
		background-size auto
		border none
		border-radius 50%
		transform translateY(-50%)

		@media tablet
			top 215px

		@media mobile
			top 139px

		&_prev
			left 0

			@media tablet
				transform none

		&_next
			right 0
			transform scaleX(-1) translateY(-50%)

			@media tablet
				transform scaleX(-1)

	&__info-block
		margin-left 30px
		display flex
		flex-direction column
		max-width 555px
		justify-self flex-end

		@media tablet
			margin-left 0
			justify-self auto
			width 100%
			max-width none

	&__title
		margin-bottom 30px
		font-family Playfair
		font-size 42px
		line-height 1.24
		letter-spacing normal

	&__paginationText
		font-size 78px
		line-height 1

		&_overall
			color: rgba(black, .3)

			&::before
				content '/'

	&__button
		@extends .text15Bold
		margin-top 25px
		padding 0
		display inline-flex
		color green
		background-color transparent
		border none
		transition color trDur trFunc
		will-change color

		@media tablet
			margin-top 32px

		&:hover,
		&:focus,
		&:active
			color greenEucalyptus

	&__buttonText
		position relative
		margin-left 15px

		@media tablet
			margin-left 10px
			font-size 22px

		@media mobile
			font-size 18px

	&__buttonIcon
		align-self center
		width 19px
		height 12px

		@media tablet
			width 24px
			height 15px

	&__list
		margin-top 25px
		padding-top 25px
		display flex
		width 536px
		text-align center
		border-top 1px solid rgba(black, .25)

		@media tablet
			margin-top 40px
			padding-top 41px
			width auto
			justify-content space-around

		@media mobile
			padding-x 25px
			padding-top 30px
			flex-wrap wrap
			justify-content space-between

	&__listItem
		margin-bottom 20px
		display flex
		flex-direction column
		align-items center

		@media tablet
			margin-bottom 40px

		@media mobile
			margin-bottom 30px
			width 33.3333%

		&:not(:last-child)
			margin-right 30px

			@media mobile
				margin-right 0

	&__itemTop
		margin-bottom 9px
		display flex
		align-items center
		height 32px
		font-size 24px
		font-weight 500
		letter-spacing normal

		@media tablet
			margin-bottom 34px
			height 34px

		@media mobile
			margin-bottom 2px
			height 32px

	&__circle
		size 20px
		border-radius 50%

		@media tablet
			size 34px

		@media mobile
			size 20px

		&_black
			background-color black

		&_blue
			background-color blueRoyal

		&_white
			background-color white
			border 1px solid rgba(grayDark, .4)

		&_red
			background-color redMahogany

	&__note
		@extends .text18Regular
		opacity .5
