.modal-form
	text-align center

	& &__title
		margin-x -11px
		margin-bottom 30px

		@media mobile
			margin-x 0
			font-size 28px

		&_margin_small
			margin-bottom 15px

	&__input-container
		margin-bottom 10px
		display flex
		flex-direction column
		text-align left

	&__button-wrap:not(:last-child)
		margin-bottom 20px

	&__description
		@extends .text15Regular
		margin-x -15px
		margin-bottom 30px
		display block

		@media mobile
			margin-x 0
