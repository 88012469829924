.slider-coaches
	position relative
	padding-right 90px
	padding-left 104px
	size 100%

	@media tablet
		padding-x 0
		text-align center

	&__slider
		overflow hidden

	&__slide
		display flex
		align-items center

		@media tablet
			flex-direction column

	&__button-controls
		@extends .defaultHover
		position absolute
		top 50%
		margin 0
		padding 0
		width 44px
		height 44px
		background url('/images/arrowLeft.svg') 50% 50% no-repeat
		background-color rgba(grayLight, .25)
		background-size auto
		border none
		border-radius 50%
		transform translateY(-50%)

		@media tablet
			top 120px

		@media mobile
			top 75px

		&_prev
			left 0

			@media tablet
				transform none

		&_next
			right 0
			transform scaleX(-1) translateY(-50%)

			@media tablet
				transform scaleX(-1)

	&__img-wrapper
		position relative
		margin-y 10px // перебить overflow для печати
		size 350px
		flex-shrink 0

		@media tablet
			margin-top 0
			margin-bottom 50px
			size 280px

		@media mobile
			margin-bottom 40px
			size 192px

	&__img
		size 100%

	&__print
		position absolute
		right 34px
		bottom -8px
		width 90px
		height 90px

		@media tablet
			right 25px
			bottom -10px
			width 80px
			height 80px
		
		@media mobile
			right 0
			bottom 0
			width 59px
			height 59px


	&__info-block
		margin-left 56px
		display flex
		flex-direction column
		align-items flex-start
		max-width 533px

		@media tablet
			margin-left 0
			width 100%
			max-width none
			flex-direction column
			align-items center

	&__title
		margin-top 2px
		margin-bottom 20px

		@media mobile
			margin-bottom 30px

	&__buttons
		margin-top 40px
		display flex
		align-items center

		@media tablet
			margin-top 35px
			flex-direction column-reverse

	&__button
		min-width 125px

	&__phone
		@extends .text15Medium
		margin-left 20px
		display flex
		flex-direction column
		align-items flex-start

		@media tablet
			margin-bottom 30px
			margin-left 0
			align-items center

	&__phone-text
		margin-bottom 8px

	&__description
		@extends .text18Regular
		max-width 100%
