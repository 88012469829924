.weather
	@extends .text15Regular
	display inline-flex
	align-items center

	&__city
		font-weight 600

	&__icon
		margin-x 8px

	&__value
		margin-right 1px

		// символ градусов
		&::after
			content '\00B0'
