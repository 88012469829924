.event-slider
	color white

	&_color_black
		color black

	&__inner-wrapper
		display flex
		flex-direction column
		align-items center

		@media tablet
			margin-x -30px

		@media mobile
			margin-x -15px

	&__title
		@media tablet
			padding-x 30px

		@media mobile
			padding-x 15px
			max-width 400px

	&__slide
		width 360px
		height auto

		@media mobile
			width 300px

	&__container
		width 100%

		@media desktop
			margin-x -10px
			padding-x 10px
			width calc(100% + 20px)

	&__wrapper
		margin-top 95px
		margin-bottom 73px
		color black
		justify-content space-between
		align-items stretch

		@media tablet
			margin-y 50px

		@media mobile
			margin-y 30px

	&__pagination
		display none

		@media mobile
			position static
			margin-bottom 31px
			display block

	// @stylint off
	.swiper-pagination-bullet
		margin 0 7px !important
		size 10px

		&-active
			background-color black !important
