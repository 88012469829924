.description-block
	width 100%

	&__text
		@extends .text22Regular
		margin-top 35px
		padding-bottom 40px
		border-bottom 1px solid rgba(black, .25)

		@media mobile
			margin-top 30px
			padding-bottom 30px

	&__list
		margin-top 40px

		@media mobile
			margin-top 30px

	&__list-item
		@extends .text15Bold

		@media tablet
			font-size 22px
			line-height 1.45
			letter-spacing -.2px

		@media mobile
			font-size 15px
			line-height 1.34
			letter-spacing normal

		&:not(:last-child)
			margin-bottom 20px

			@media tablet
				margin-bottom 35px

			@media mobile
				margin-bottom 20px
