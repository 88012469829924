.header
	@extends .text15Regular
	position fixed
	z-index 5
	top 0
	left 0
	padding-top 20px
	padding-bottom 16px
	width 100%
	background-color white
	transition-duration trDur
	transition-timing-function trFunc
	transition-property color, background-color
	will-change color, background-color

	@media tablet
		padding-y 23px
		transition none

	&::before,
	&::after
		position absolute
		content ''

	// тень
	&::before
		top 0
		right 0
		left 0
		bottom 0
		opacity 0
		box-shadow 0 3px 15px 0 rgba(black, .07)
		transition opacity trDur trFunc
		will-change opacity

	// серый горизонтальный разделитель
	&::after
		top 78px
		left 50%
		width 1140px
		max-width 100%
		height 1px
		background-color rgba(black, .1)
		transition background-color trDur trFunc
		transform translateX(-50%)
		will-change background-color

		@media tablet
			display none

	&_theme_ghost
		color white
		background-color transparent

		&::after
			background-color rgba(white, .3)

		@media tablet
			color black
			background-color white

	&_theme_transparent
		color white
		background-color transparent

		&::after
			background-color rgba(white, .3)

	&_theme_ghost &__nav-sublist
	&_theme_transparent &__nav-sublist
		background-color rgba(black, .55)

		&::before
			border-color transparent transparent rgba(black, .55) transparent

	&_with-shadow
		color black
		background-color white

		&::after
			background-color rgba(black, .1)

		&::before
			opacity 1

	&_with-shadow &__nav-sublist
		background-color rgba(whiteSmoke, .95)

		&::before
			border-color transparent transparent rgba(whiteSmoke, .95) transparent

	&_menu-open
		@media tablet
			color white
			background-color black
			box-shadow none

	&_menu-open &__mobile-menu-wrapper
		@media tablet
			visibility visible

	&_menu-open &__burger
		display none

	&_menu-open &__close
		display block

	&__burger,
	&__close
		position relative // ie11 fix

	&__close
		display none

	&__mobile-menu-wrapper
		position fixed
		top 85px
		right 0
		left 0
		bottom 0
		visibility hidden
		overflow auto
		width 100%

		@media mobile
			top 78px

	&__wrapper
		position relative
		display flex
		width 100%
		justify-content space-between
		align-items center

		&_main
			margin-bottom 27px

			@media tablet
				margin-bottom 0

		&_secondary
			@media tablet
				display none

	&__wrapper-part
		display flex
		width 875px
		align-items center

		@media tablet
			width 100%

		@media mobile
			width calc(100% - 32px)

	&__menu-toggler
		@extends .defaultHover
		margin-right 30px
		padding 0
		display none
		width 39px
		height 26px
		color inherit
		background-color transparent
		border none

		@media tablet
			display block

		@media mobile
			margin-right 0

	&__logo
		@extends .defaultHover
		margin-right auto
		width 100px
		height 20px
		color inherit

		@media tablet
			width 127px
			height 26px

		@media mobile
			margin-x auto
			width 89px
			height 18px

	&__phone-link
		@extends .defaultHover
		margin-right 34px
		font-weight 500
		text-decoration none
		color inherit

		@media tablet
			display none

	&__callback
		margin-top -3px

		@media tablet
			display none

	&__profile-link
		@extends .defaultHover
		display flex
		text-decoration none
		color inherit
		align-items center

		@media tablet
			margin-left 31px
			flex-shrink 0

		@media mobile
			margin-left 0

		&_notification
			position relative

			// красный кружок в углу аватарки
			&::after
				position absolute
				right 0
				bottom 0
				width 8px
				height 8px
				content ''
				background-color redCinnabar
				border 2px solid white
				border-radius 50%

	&__profile-name
		@media mobile
			display none

	&__profile-image
		margin-left 15px
		border-radius 50%
		size 29px

		@media tablet
			size 39px

		@media mobile
			margin-left 0
			size 32px

	&__weather-wrapper
		margin-right auto

	&__nav-list
		display flex

	&__nav-item
		position relative
		padding-y 12px

		&:not(:last-child)
			margin-right 24px

	&__nav-item:hover &,
	&__nav-item[aria-expanded='true'] &
		&__nav-sublist
			pointer-events auto
			opacity 1

	&__nav-link,
	&__nav-sublink
		font-weight 500
		text-decoration none
		color inherit
		opacity .6
		transition opacity trDur trFunc
		will-change opacity

		&:hover,
		&:focus
			opacity .3

		&_active,
		&:active
			opacity 1

	&__nav-sublist
		position absolute
		top 42px
		left -17px
		padding 20px
		pointer-events none
		background-color rgba(whiteSmoke, .95)
		opacity 0
		box-shadow 0 2px 4px 0 rgba(black, .17)
		transition opacity trDur trFunc
		will-change opacity

		// выпирающий треугольник
		&::before
			position absolute
			top -10px
			left 30px
			width 0
			height 0
			content ''
			border-width 0 10px 10px 10px
			border-style solid
			border-color transparent transparent rgba(whiteSmoke, .95) transparent

	&__nav-subitem
		&:not(:last-child)
			margin-bottom 20px

	&__nav-sublink
		white-space nowrap

	&__lang-wrapper
		margin-right -3px
