.intro
	position relative
	padding-y 80px
	display flex
	flex-direction column
	justify-content center
	min-height 450px
	text-align center
	color white
	background-repeat no-repeat
	background-position center
	background-size cover

	@media tablet
		margin-x -30px
		padding-x 30px
		min-height 768px

	@media mobile
		margin-x -15px
		padding-x 15px
		min-height 464px

	&::before
		position absolute
		z-index 1
		top 0
		left 0
		size 100%
		content ''
		background-color rgba(0, 0, 0, .25)

	&__inner
		position relative
		z-index 2

	&_type_golf-club
		retinaImage('golf-club-page/club-intro', 'jpg')

	&_type_golf-academy
		retinaImage('golf-academy-page/golf-academy', 'jpg')

	&_type_novice
		retinaImage('novice-page/intro', 'jpg')

	&_type_premium
		retinaImage('premium-page/premium', 'jpg')

	&_type_news
		&::before
			position absolute
			top 0
			left 0
			size 100%
			content ''
			background-color rgba(black, .6)

	&_type_news &__text
		max-width 475px

		@media tablet
			max-width 577px
			font-size 30px
			line-height 1.23

		@media mobile
			font-size 18px
			line-height 1.44
			letter-spacing -.4px

	&_size_small
		margin-x 0
		padding-x 0
		min-height 400px

		@media mobile
			margin-x -15px
			padding-x 15px
			min-height 464px

	&__title
		margin-right auto
		margin-left auto
		max-width 810px

		@media tablet
			max-width 545px

		@media mobile
			max-width 500px

	&__text
		@extends .text22Regular
		margin-top 30px
		margin-right auto
		margin-left auto
		max-width 750px

		@media tablet
			margin-top 40px
			max-width 698px

		@media mobile
			margin-top 30px
			max-width 520px
			font-size 18px

	&__date
		@extends .text15Medium

		@media tablet
			font-size 18px

		@media mobile
			font-size 15px
