.input-box
	position relative
	overflow hidden
	width 100%
	min-height 64px

	&_with-label &__input
		padding-left 132px
		text-align right

	&_view_email &__input
		padding-left 60px

	&_error &
		&__input
			border-color redWarning

		&__warning
			visibility visible
			opacity 1

	&__input
		padding 8px 12px
		width 100%
		height 38px
		font-size 15px
		border 1px solid grayLight
		border-radius 2px
		outline none
		transition border-color trDur trFunc
		will-change border-color

		&:focus
			border-color gray

		&_textarea
			height 150px

	&__label
		@extends .text15Medium
		position absolute
		top 10px
		left 12px
		overflow hidden
		max-width 120px
		white-space nowrap
		text-overflow ellipsis

	&__warning
		margin-y 4px
		display block
		visibility hidden
		font-size 12px
		letter-spacing normal
		color redWarning
		opacity 0
		transition opacity trDur trFunc
		will-change opacity
