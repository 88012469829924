.slider-gallery
	margin-x auto
	width 946px
	border-bottom 1px solid rgba(black, .1)

	@media tablet
		width auto

	@media mobile
		border none

	&__slide
		display flex
		justify-content stretch

	&__image-col
		display flex
		flex-wrap wrap
		flex-grow 1
		height 350px

		@media tablet
			height 270px

		@media mobile
			flex-wrap nowrap

		&:not(:last-child)
			margin-right 30px

			@media mobile
				margin-right 16px

		&_first
			flex-basis 458px

			@media tablet
				flex-basis 324px

		&_second
			flex-basis 263px

			@media tablet
				flex-basis 204px

		&_third
			flex-basis 166px

			@media tablet
				flex-basis 120px

		&_first,
		&_second,
		&_third
			@media mobile
				flex-basis auto

	&__img
		size 100%
		background-repeat no-repeat
		background-position 50% 50%
		background-size cover

		&_small
			height 160px

			@media tablet
				height 120px

			&:not(:last-child)
				margin-bottom 30px

				@media mobile
					margin-right 16px
					margin-bottom 0

		&,
		&_small
			@media mobile
				width 329px
				height 378px

	&__controls
		margin-y 15px
		display flex
		user-select none
		align-items center
		justify-content center

		@media mobile
			display none

	&__pagination
		@extends .text18Regular
		position static
		margin-x 22px
		margin-y 0
		width auto

		@media mobile
			display none

	&__button
		position relative
		top -1px
		right 0
		left 0
		margin 0
		width 10px
		height 14px
		background url('/images/arrowLeft.svg') 50% 50% no-repeat
		background-size contain

		&_next
			transform rotate(180deg)

	.swiper-slide
		@media mobile
			width auto
