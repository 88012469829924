.modal
	position relative
	margin auto
	padding-x 55px
	padding-y 60px
	width 100%
	background-color white

	@media tablet
		padding-x 100px

	@media mobile
		padding-x 20px

	&_type_md
		max-width 360px

		@media tablet
			max-width 450px

	&_type_lg
		padding-x 30px
		padding-top 70px
		padding-bottom 60px
		max-width 555px

		@media tablet
			padding 40px
			padding-top 70px

		@media mobile
			padding-x 16px
			padding-top 70px
			padding-bottom 30px

	&_type_media
		padding 10px
		width 750px
		line-height 0

		@media tablet
			width 660px
			padding 10px

		@media mobile
			padding 10px
			width 100%

	&_no-padding
		padding 0

	&__text-button
		@extends .text15Bold
		position absolute
		top 20px
		left 20px
		padding 0
		color green
		background-color transparent
		border none
		transition color trDur trFunc
		will-change color

		@media tablet
			left 20px

		@media mobile
			left 16px

		&_right
			right 20px
			left auto

			@media tablet
				right 20px
				left auto

			@media mobile
				right 16px
				left auto

		&:hover,
		&:active,
		&:focus
			color greenEucalyptus

	&__text-button-icon
		position absolute
		top 3px
		left 0
		width 8px
		height 14px
		line-height 0

	&__text-button_right &__text-button-icon
		transform scale(-1)
		right 0
		left auto

	&__text-button-inner
		position relative
		padding-x 18px

	&__close-button
		position absolute
		top 16px
		right 20px
		padding 0
		width 32px
		height 32px
		color black
		background-color transparent
		border none
		opacity .2
		transition opacity trDur trFunc
		will-change opacity

		@media mobile
			top 10px
			right 10px

		&:hover,
		&:active,
		&:focus
			opacity .3

	&__close-button-icon
		position relative // ie11 fix

	&_type_media &__close-button
		top auto
		right auto
		left calc(100% + 5px)
		bottom calc(100% + 5px)
		color white

		@media mobile
			right 0
			left auto
