.agreement
	&_with-error &__error
		visibility visible
		opacity 1

	&__error
		margin-y 4px
		display block
		visibility hidden
		font-size 12px
		letter-spacing normal
		color redWarning
		opacity 0
		transition opacity trDur trFunc
		will-change opacity

	&__link
		@extends .defaultHover
		color green
