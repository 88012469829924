.submenu
	position relative
	display flex
	flex-direction column

	@media tablet
		flex-direction row
		border-top 1px solid darkSmoke
		flex-wrap wrap
		overflow hidden

	// Линия закрывающая крайний бордер // TODO: нужен ответ диза
	// &::after
	// 	position absolute
	// 	top -2px
	// 	right -1px
	// 	width 2px
	// 	height 103%
	// 	content ''
	// 	background-color #d3d3d3

	// 	@media mobile
	// 		display none

	&__item
		line-height 1
		outline none

		@media tablet
			border-bottom 1px solid darkSmoke
			flex-grow 1
			min-width 25%

		@media mobile
			min-width 50%

		&:not(:last-child)
			margin-bottom 17px

			@media tablet
				margin-bottom 0
				border-right 1px solid darkSmoke

			@media mobile
				border-right none

		&:nth-child(odd)
			@media mobile
				border-right 1px solid darkSmoke

		&:last-child
			@media mobile
				border-right none

	&__link
		@extends .text15Regular
		position relative
		color rgba(black, .4)
		background-color transparent
		border none
		transition color trDur trFunc
		will-change color

		@media tablet
			padding-y 14px
			display flex
			width 100%
			height 100%
			justify-content center

			@media mobile
				padding-y 16px
				font-size 13px

		&:hover:not(:disabled):not(:active),
		&:focus
			color rgba(black, .6)

		&:active
			color black

		&_selected
			cursor default
			color black

			&:hover:not(:disabled):not(:active),
			&:focus
				color black

		&_marker
			padding-left 5px

			@media tablet
				padding-left 0

			// Маркер (красная точка)
			&::before
				position absolute
				top 0
				left 0
				width 5px
				height 5px
				content ''
				background-color redCinnabar
				border-radius 50%

				@media tablet
					position static

	// for IE11
	&__text
		position relative
		top 0
		left 0
