.tournament-details
	&__text
		@extends .text22Regular
		margin-top 70px
		letter-spacing -.018em

		@media tablet
			margin-top 40px

		@media mobile
			margin-top 30px
			font-size 18px
			line-height 1.456
			letter-spacing -.03em

		&::first-letter
			padding-right 18px
			padding-left 3px
			float left
			font-family Playfair
			font-size 62px
			line-height .94

			@media tablet
				padding 0
				float none
				font-family Avenir
				font-size 22px
				line-height 1.456

			@media mobile
				font-size 18px
				line-height 1.456
