.main-page
	&__map-wrapper
		height 603px

		@media tablet
			height 620px

		@media mobile
			height 450px

	&__feature-item
		margin-bottom 30px
		margin-x auto
		display flex
		justify-content space-between
		max-width 1140px

		@media tablet
			flex-direction column
			align-items center

		@media mobile
			margin-bottom 16px

		&_reverse
			@media desktop
				flex-direction row-reverse

		&_top
			margin-top 100px

			@media tablet
				margin-top 30px

			@media mobile
				margin-top 16px

		&_bottom
			margin-bottom 100px

			@media tablet
				margin-bottom 30px

			@media mobile
				margin-bottom 16px

	&__feature-card
		width 555px

		@media tablet
			width 708px
			height 325px

		@media mobile
			width 288px
			height 166px

		&:not(:last-child)
			@media tablet
				margin-bottom 30px

			@media mobile
				margin-bottom 16px

	&__event-slider
		position relative
		padding-y 110px
		background-image linear-gradient(to bottom, greenVeryDark 528px, white 0)

		@media tablet
			padding-y 100px
			background-image linear-gradient(to bottom, greenVeryDark 473px, white 0)

		@media mobile
			padding-y 50px
			background-image linear-gradient(to bottom, greenVeryDark 392px, white 0)

		// фоновый мяч для гольфа
		&::before
			position absolute
			top 72px
			left 50%
			width 863px
			height 456px
			content ''
			background-image url('/images/event-slider/ball.png')
			background-size cover
			transform translateX(-50%)

			@media retina
				background-image url('/images/event-slider/ball@2x.png')

			@media tablet
				display none
