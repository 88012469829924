.news-banner
	position relative
	padding-x 20px
	padding-top 25px
	display block
	height 100%
	text-decoration none
	text-align center
	color white
	background-color green

	@media mobile
		height 495px
		color black
		background-color whiteSmoke

	&:hover::after
		opacity 1

	// тень при ховере
	&::after
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		content ''
		opacity 0
		box-shadow 0 9px 16px 0 rgba(black, .08)
		transition opacity trDur trFunc
		will-change opacity

	&__inner
		margin-x auto
		display flex
		flex-direction column
		justify-content center
		max-width 300px
		height 100%

		@media mobile
			padding-top 0

	&__subtitle
		margin-bottom 10px
		font-family Avenir
		font-size 15px
		font-weight 600

		@media mobile
			margin-bottom 5px

	&__circles
		position absolute
		top 0
		left 0
		overflow hidden
		size 100%

	&__circle
		position absolute
		border 2px solid white
		border-radius 50%
		opacity .2

		@media mobile
			border-color grayDark

		// Дополнительные фоновые кольца
		&::after
		&::before
			position absolute
			content ''
			border 2px solid white
			border-radius 50%

			@media mobile
				border-color grayDark

		&_top
			top -15px
			right -25px
			size 120px

			// Верхнее большое фоновое кольцо
			&::before
				top -55px
				left -58px
				width 229px
				height 225px

			// Верхнее маленькое фоновое кольцо
			&::after
				top 146px
				left 36px
				width 110px
				height 105px

		&_bottom
			left -61px
			bottom -25px
			width 120px
			height 120px

			// Нижнее маленькое фоновое кольцо
			&::before
				display none

			// Нижнее большое фоновое кольцо
			&::after
				top -58px
				left -62px
				width 232px
				height 230px
