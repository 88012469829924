.contacts-page
	&__map
		height 610px

		@media tablet
			height 66vh
			min-height 570px

		@media mobile
			height auto
			min-height 0
			background-color white

	&__contacts
		padding-top 110px
		padding-bottom 95px
		background-color whiteSmoke

		@media tablet
			padding-top 97px
			padding-bottom 0

		@media mobile
			padding-y 20px

