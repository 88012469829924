/* Color definitions
** Use this service to retrieve color names:
** http://www.color-blindness.com/color-name-hue
*/

/* colors */
white = #fff
whiteSmoke = #f9f9f9
darkSmoke = #eaeaea
black = #000
charcoal = #101212

green = #3eb65e
greenDark = #020410
greenEucalyptus = #2da24c
greenVeryDark = #033011

gray = #bfbfbf
grayDark = #979797
grayLight = #d8d8d8
grayGainsboro = #e8e8e8

blueAirForce = #5f7fa2
blueSanMarino = #4d7198
blueFb = #4e69a2
blueMariner = #3b5998
blueRoyal = #4277dd

redWarning = #ff4c4c
redCinnabar = #eb542d
redMahogany = #bf3a31

/* transitions */
trDur = .3s
trFunc = linear

/* fonts */
Avenir = 'AvenirNext', 'Arial', sans-serif
Playfair = 'Playfair Display', serif
Cormorant = 'CormorantGaramond', sans-serif

/* media */
desktop = 'screen and (min-width: 1240px)'
tablet = 'screen and (max-width: 1239px)'
mobile = 'screen and (max-width: 767px)'
retina = 'screen and (min-resolution: 192dpi)'
tabletRetina = 'screen and (min-resolution: 192dpi) and (max-width: 1239px)'
mobileRetina = 'screen and (min-resolution: 192dpi) and (max-width: 767px)'
