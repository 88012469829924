.page-404
	position relative
	padding-top 144px
	display flex
	flex-direction column
	justify-content center
	height 1px
	min-height calc(100vh - 60px)
	text-align center
	color white
	background-color greenVeryDark
	background-repeat no-repeat
	background-position 50% 50%
	background-size cover
	retinaImage('main-page/head-bg', 'jpg')

	@media tablet
		padding-top 85px
		min-height calc(100vh - 119px)

	@media mobile
		padding-top 78px
		min-height calc(100vh - 143px)

	// overlay на фоне
	&::before
		position absolute
		top 0
		left 0
		size 100%
		content ''
		background-color rgba(black, .6)

	&__image
		width auto
		height auto

	&__sub-title
		@extends .text18Medium
		margin-top 33px
		margin-bottom 80px

		@media mobile
			margin-top 20px
			margin-bottom 30px
			font-size 15px

	&__inner
		position relative

		// картинка 404
		&::before
			display inline-block
			width 387px
			height 157px
			content ''
			background-image url('/images/404.svg')
			background-repeat no-repeat
			background-position center center
			background-size contain

			@media mobile
				width 190px
				height 77px
