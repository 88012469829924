.news-single-page
	padding-top 8px
	padding-bottom 100px

	@media tablet
		padding-bottom 50px

	&__header
		@media tablet
			margin-x -30px

	&__return-link
		margin-top 20px

	&__wrapper
		margin-x auto
		padding-top 50px
		padding-bottom 97px
		max-width 946px
		border-bottom 1px solid rgba(black, .2)

		@media tablet
			padding-top 17px
			padding-bottom 42px

		@media mobile
			padding-top 8px
			padding-bottom 26px

	&__inner
		margin-x auto
		max-width 750px

	&__big-text
		@extends .text32Medium
		margin-bottom 80px

		@media tablet
			margin-bottom 40px

		@media mobile
			margin-bottom 30px

	&__text
		@extends .text22Regular
		margin-bottom 50px

		@media mobile
			font-size 18px
			margin-bottom 45px

		&_capital::first-letter
			@extends .capital-letter

	&__quote
		margin-x auto
		margin-bottom 70px
		max-width 600px

		@media mobile
			margin-bottom 50px

	&__sub-title
		margin-top 50px
		margin-bottom 70px

		@media tablet
			margin-top 0
			margin-bottom 45px

		@media mobile
			margin-bottom 32px

	&__slider
		margin-top 20px

		@media tablet
			margin-top 45px

		@media mobile
			margin-right -15px
			margin-left -15px
			margin-top 30px

	&__sharings
		margin-top 20px

		@media tablet
			margin-top 8px

		@media mobile
			margin-top -17px

	&__event-slider
		margin-top 84px

		@media tablet
			margin-top 50px

		@media mobile
			margin-top 40px
