.table
	position relative
	padding-top 52px
	width 100%
	text-align left
	color inherit
	background-color white
	border 1px solid grayGainsboro

	@media tablet
		padding 0
		overflow auto
		max-height 384px

	@media mobile
		width 100%

	// имитация бордера и тени для шапки
	&::before
		position absolute
		top 0
		right 0
		left 0
		height 52px
		content ''
		border-bottom 1px solid grayGainsboro
		box-shadow 0 10px 20px 0 rgba(black, .05)

		@media tablet
			display none

	&__wrapper
		overflow-x hidden
		overflow-y auto
		max-height 320px

		@media tablet
			display none

	&__main
		width 100%

		&_mobile
			@media desktop
				display none

	&__cell
		@extends .text15Regular
		padding 16px
		background-color transparent
		border 1px solid grayGainsboro

		@media tablet
			padding-x 12px
			padding-y 8px

		&:first-child
			border-left none

		&:last-child
			border-right none

		&_header
			font-weight 500
			white-space nowrap
			background-color white

			@media tablet
				position sticky
				top 0
				padding 16px
				background-clip padding-box // FF fix
				border-top none
				box-shadow 0 10px 20px -1px rgba(black, .05)

				// имитация бордера для стики хедера
				&::after
					position absolute
					right 0
					left 0
					height 1px
					content ''
					background-color grayGainsboro

				&::after
					bottom -1px

			@media mobile
				font-size 13px
				text-align center

		&_faked
			padding 0
			font-size 0
			line-height 0

	&__cell_header:first-child &__fake
		border none

	&__fake
		position absolute
		top 1px
		padding 16px
		display block
		font-size 15px
		line-height normal
		pointer-events none // enable x-scrolling on touch devices
		color black
		border-left 1px solid grayGainsboro
		transform translateX(-1px)
