.main
	padding-top 144px
	flex-grow 1

	@media tablet
		padding-top 85px

	@media mobile
		padding-top 78px

	&_no-paddings
		padding 0

	&_empty-paddings
		padding 0

		@media tablet
			padding 0

		@media mobile
			padding 0
