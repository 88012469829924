.tournament-slider
	&__inner-wrapper
		display flex
		flex-direction column
		align-items center

		@media tablet
			margin-x -30px

		@media mobile
			margin-x -15px

	&__title
		@media tablet
			padding-x 30px

		@media mobile
			padding-x 15px

	&__slide
		width 360px
		height 500px

		@media mobile
			width 300px

	&__container
		padding-top 70px
		padding-bottom 60px
		width 100%

		@media tablet
			padding-top 40px
			padding-bottom 0

		@media mobile
			padding-top 30px

	&__pagination
		position static
		margin-y 30px
		display none

		@media tablet
			display block

	// @stylint off
	.swiper-pagination-bullet
		margin 0 7px !important
		size 10px

		&-active
			background-color black !important
