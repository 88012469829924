.album-page
	padding-top 50px
	padding-bottom 100px
	text-align center

	@media tablet
		padding-top 62px

	@media mobile
		padding-top 19px
		padding-bottom 50px

	&__date
		@extends .text15Bold
		padding-bottom 3px

		@media tablet
			padding-bottom 5px
			font-size 22px
			font-weight normal

		@media mobile
			padding-bottom 0
			font-size 15px
			font-weight 500

	&__slider
		margin-x auto
		margin-top 70px
		padding-x 73px
		max-width 1092px

		@media tablet
			margin-top 60px
			padding-x 0
			max-width 946px

		@media mobile
			margin-top 35px
