.tournament-modal
	text-align center

	&__text
		@extends .text15Regular
		margin-x auto
		margin-top 15px
		max-width 360px

		&_narrow
			max-width 224px

	&__button
		margin-top 40px

		@media mobile
			width 100%

		&_small-margin
			margin-top 4px

	&__line
		position relative
		margin-y 45px
		width 100%
		height 1px
		background-color rgba(black, .25)

		// звёздочка
		&::after
			position absolute
			top -10px
			left 50%
			width 43px
			height 15px
			transform translateX(-50%)
			font-family serif
			font-size 28px
			line-height 1
			content '*'
			color rgba(black, .25)
			background-color #fff

	&__pay-content
		position relative
		margin-top 40px
		padding-top 40px
		display flex
		justify-content space-between
		flex-wrap wrap

		@media mobile
			margin-top 30px
			padding-top 30px
			flex-direction column

		// разделительная линия
		&::before
			position absolute
			top 0
			width 100%
			height 1px
			content ''
			background-color rgba(black, .25)

	&__input-group
		margin-bottom 30px
		display flex
		flex-direction column
		max-width 240px
		text-align left

		@media mobile
			margin-bottom 42px
			max-width none

	&__checkbox-wrapper
		margin-top 10px

	&__radio-wrapper
		margin-bottom 10px

	&__price-block
		text-align right

		@media mobile
			display flex
			justify-content space-between
			text-align left

	&__info
		@extends .text15Regular
		width 250px
		text-align left
		opacity .5

		@media mobile
			width auto
			text-align center
			order 2

	&__input
		max-width 165px

		@media mobile
			margin-right 23px
			order -1

		// TODO: refactoring
		& .input-box__input
			padding-left 100px

		&_full-width
			@media mobile
				margin-right 0
				width 100%

	&__price
		margin-bottom 18px
		display block
		font-size 32px
		font-weight 500
		line-height 1.3
		letter-spacing -.8px
		flex-shrink 0

		@media mobile
			margin-bottom 0

	&__pay-button
		min-width 165px

		@media mobile
			margin-top 5px
			margin-bottom 30px
			width 100%

	&__link
		margin-top 20px

	&__inputs-wrapper
		margin-x auto
		margin-top 30px
		display flex
		max-width 340px
		justify-content space-between

	&__item
		display flex
		flex-direction column
		width 100%

		&:not(:last-child)
			margin-bottom 25px

	&__name
		@extends .text15Regular
		color rgba(black, .6)

	&__value
		@extends .text18Medium
		font-family Cormorant

		@media mobile
			flex-direction column
			max-width none

		&_big
			font-family Avenir
			font-size 32px
			font-weight 500

	&__currency
		font-weight 300
