.detailed-page
	margin-top 24px

	@media tablet
		margin-top 0

	&__groups
		margin 90px auto 100px
		width 946px

		@media tablet
			margin-bottom 50px
			width 100%

		@media mobile
			margin-y 40px

	&__gallery
		margin-bottom 94px

		@media tablet
			margin-bottom 50px

		@media mobile
			margin-x -15px
			margin-bottom 40px

	&__gallery-title
		margin-bottom 70px
		margin-left 195px

		@media tablet
			margin-bottom 50px
			margin-left 30px

		@media mobile
			margin-bottom 30px
			margin-left 15px

	&__details
		margin-x auto
		margin-bottom 70px
		width 750px

		@media tablet
			margin-x 0
			margin-bottom 50px
			width 100%

		@media mobile
			margin-bottom 35px

	&__sharing
		margin-x auto
		margin-bottom 100px
		width 750px

		@media tablet
			margin-x 0
			width 100%

		@media mobile
			margin-bottom 54px
