.tournament-page
	& &__title
		padding-top 50px
		padding-bottom 80px
		text-align center

		@media tablet
			padding-y 70px
			font-size 78px
			line-height 1.06
			letter-spacing 1.2px

		@media mobile
			padding-top 50px
			padding-bottom 40px
			font-size 42px
			line-height 1.17
			letter-spacing normal
