.groups-info
	padding-bottom 32px
	border-bottom 1px solid rgba(black, .25)

	@media tablet
		padding-bottom 44px

	@media mobile
		padding-bottom 0

	&__inner
		margin-x auto
		display flex
		flex-wrap wrap
		width 780px

		@media tablet
			width 100%

	&__group
		padding-x 10px
		padding-bottom 60px
		width 50%
		text-align center

		@media tablet
			padding-bottom 34px

		@media mobile
			padding-bottom 14px
			width 100%

	&__title
		@extends .text22Medium
		margin-bottom 9px

		@media tablet
			margin-bottom 10px

	&__text
		@extends .text18Regular

		@media tablet
			margin 8px auto 15px
			max-width 333px
			line-height 1.85

		@media mobile
			max-width 100%
			line-height 1.5
