.news-slider
	position relative
	width 100%

	&__img
		display block
		width 100%
		height auto

		@media tablet
			height 100%

	&__wrapper
		flex-shrink 0

	&__slide
		@media tablet
			height 709px

		@media mobile
			width 300px
			height 200px

	&__controls
		padding 25px 19px 18px
		display flex
		user-select none
		justify-content center
		flex-shrink 0
		border-bottom 1px solid rgba(black, .2)

		@media mobile
			padding 0

	&__pagination
		@extends .text18Regular
		position static
		margin-x 26px
		margin-y 0
		display flex
		width auto
		color rgba(black, .4)

		@media mobile
			position static
			margin-y 30px
			display block

		.swiper-pagination-current
		.swiper-pagination-total
			padding-x 5px
			display flex

		.swiper-pagination-current
			color black


		.swiper-pagination-bullet
			margin 0 7px !important
			size 10px

			&-active
				background-color black !important

	&__button
		@extends .defaultHover
		position static
		top 0
		left 0
		margin-top 2px
		display block
		width 15px
		height 20px
		cursor pointer
		background url('/images/arrowLeft.svg') center center no-repeat
		border none
		opacity .3
		transition opacity .3s ease-out

		@media mobile
			display none

		&:hover
			opacity 1

		&_next
			transform rotate(180deg)

		&[aria-disabled='true']
			cursor default

			&:hover
				opacity .3
