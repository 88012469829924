.sharings
	&__link
		@extends .text18Medium
		@extends .defaultHover
		display inline-flex
		line-height 1
		text-decoration none
		color inherit
		align-items flex-start

		@media tablet
			align-items center

		&:not(:last-child)
			margin-right 40px

			@media tablet
				margin-right 70px

			@media mobile
				margin-right 40px

	&__icon
		margin-right 10px
		width 30px
		height 18px

		@media tablet
			margin-right 14px
			width 44px
			height 28px

		@media mobile
			margin-right 10px
			width 30px
			height 18px
