.reservation-block
	padding-y 100px
	text-align center
	background-color whiteSmoke

	@media mobile
		padding-top 40px
		padding-bottom 50px

	&__text-box
		position relative
		margin-top 75px
		margin-bottom 90px
		display flex
		justify-content space-between
		text-align left

		@media tablet
			margin-top 40px
			margin-bottom 90px
			flex-direction column
			justify-content flex-start

		@media mobile
			margin-top 30px
			margin-bottom 108px

	&__title
		@media mobile
			text-align left

	&__text
		@extends .text22Regular
		max-width 555px

		@media tablet
			max-width 100%

		&:first-child
			margin-right 30px

			@media tablet
				margin-right 0
				margin-bottom 40px

			@media mobile
				margin-bottom 30px

	&__signature
		transform rotate(-25deg)
		position absolute
		right 46px
		bottom -80px
		width 124px
		height 80px

		@media tablet
			right 0

		@media mobile
			right 50%
			bottom -80px
			width 74px
			height 50px
			transform: translateX(50%) rotate(-25deg)
