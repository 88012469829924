.tournament-card
	@extends .card-fade
	position relative
	z-index 0
	padding 20px
	display flex
	flex-direction column
	justify-content flex-end
	width 100%
	height 100%
	overflow hidden
	background-color whiteSmoke
	transition-duration trDur
	transition-timing-function trFunc
	transition-property color, background-color, transform
	will-change color, background-color, transform

	// apply hover/focus effects only at desktop
	@media desktop
		&:hover,
		&:focus
			color white
			background-color green
			transform translateY(-5px)

		&:hover &__button,
		&:focus &__button
			color green
			background-color white

			&:hover
				background-color rgba(white, .9)

		&:hover::after,
		&:focus::after
			opacity 1

		// тень при ховере
		&::after
			position absolute
			top 0
			right 0
			left 0
			bottom 0
			pointer-events none
			content ''
			opacity 0
			box-shadow 0 9px 16px 0 rgba(black, .08)
			transition opacity trDur trFunc
			will-change opacity

	&__date
		@extends .text15Bold
		margin-bottom 5px

	&__content-type
		@extends .text18Medium
		display flex
		align-items center
		margin-top 15px
		margin-bottom 30px

	&__type
		margin-right 6px

	// TODO: уточняем как позиционировать при вертикальном выравнивании
	&__icon
		margin-right 8px
		margin-left auto
		flex-shrink 0
		width 22px
		height 21px

	&__button
		position relative
		z-index 10
		transition-duration trDur
		transition-timing-function trFunc
		transition-property color, background-color
		will-change color, background-color

	&__animation-wrapper
		position absolute
		z-index 5
		top -31px
		right -13px
