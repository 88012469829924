.about-section
	margin-x -15px
	display flex
	flex-wrap wrap

	&__col
		padding-x 15px
		width 50%

		@media tablet
			width 100%

		&:first-child
			@media tablet
				margin-bottom 35px

	&__title
		@extends .text32Medium
		padding-bottom 50px
		border-bottom 1px solid rgba(black, .25)

		@media tablet
			padding-bottom 40px

		@media mobile
			padding-bottom 32px

	&__list
		margin-x -15px
		margin-top 25px
		display flex
		flex-wrap wrap

		@media tablet
			margin-x -30px
			margin-top 0
		
		@media mobile
			margin-x 0
			margin-top 5px

	&__listItem
		margin-top 23px
		padding-x 15px
		display flex
		flex-direction column
		width 50%

		@media tablet
			margin-top 40px
			padding-x 30px

		@media mobile
			margin-top 25px
			padding-x 0
			width 100%

		&:first-child
			@media mobile
				order -2

		&:nth-child(3)
			@media mobile
				order -1

	&__listTitle
		@extends .text22Medium
		margin-bottom 10px

		@media tablet
			font-size 25px
			line-height 1.36
			letter-spacing normal

		@media mobile
			margin-bottom 0
			font-size 22px
			line-height 1.55

	&__listText
		@extends .text18Regular

		@media tablet
			font-size 22px
			line-height 1.45
			letter-spacing -0.2px

		@media mobile
			font-size 18px
			line-height 1.44
			letter-spacing -0.4px

	&__paragraph
		@extends .text22Regular

		&:not(:last-child)
			margin-bottom 30px
