.lifestyle-section
	padding-top 142px
	padding-bottom 139px
	color white
	background-color greenVeryDark
	background-repeat no-repeat
	background-position 100% 100%
	background-size 578px 514px
	overflow hidden
	retinaImage('main-page/ball-bg')

	@media tablet
		padding-top 139px
		padding-bottom 100px
		background-position 109% 0
		background-size 580px 469px

	@media mobile
		padding-top 82px
		padding-bottom 50px
		background-position 100% 100%
		background-size 270px 340px

	&__inner
		display flex
		align-items center
		justify-content space-between

		@media tablet
			flex-direction column

	&__image
		width 509px
		height 369px
		background-repeat no-repeat
		background-position 50% 50%
		background-size contain

		@media tablet
			margin-bottom 50px
			width 558px
			height 405px

		@media mobile
			margin-bottom 40px
			width 292px
			height 216px

	&__text-block
		max-width 588px

		@media tablet
			max-width none
			width 100%

	&__title
		max-width 450px

		@media tablet
			max-width 400px

	&__text
		@extends .text22Regular
		margin-top 37px

		@media tablet
			margin-top 40px

		@media mobile
			margin-top 30px
