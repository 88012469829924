.interactive-map
	position relative
	height 100%

	&__contact-info
		padding-bottom 20px
		max-width 350px

		@media tablet
			margin-x auto
			padding-bottom 30px
			max-width 710px
			text-align center

		@media mobile
			margin-top -80px
			padding-bottom 25px
			max-width 350px

	&__button
		margin-top 50px
		pointer-events initial

		@media mobile
			margin-top 40px

	&__inner
		position relative
		height 100%
		pointer-events none

		&_contact
			@media mobile
				height auto

	&__text-wrapper
		position relative
		z-index 2
		padding-bottom 50px
		display flex
		flex-direction column
		align-items flex-start
		justify-content flex-end
		height 100%

		@media tablet
			padding-bottom 69px

		@media mobile
			padding-bottom 25px

	&__text
		@extends .text18Regular
		margin-top 15px

		@media tablet
			margin-top 29px

		@media mobile
			margin-top 18px

		&_min
			max-width 200px

	&__iframe
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		overflow hidden

		&_contact
			@media mobile
				position static
				height 66vh

	&__title
		font-family Playfair
		font-size 58px
		line-height 1

		@media tablet
			padding-bottom 5px
			font-size 78px

		@media mobile
			padding-bottom 0
			font-size 42px

	&__sub-title
		@extends .text22Medium
		margin-top 40px

		@media tablet
			margin-top 45px
			font-size 25px

		@media mobile
			font-size 22px

	&__paragraph
		@extends .text18Regular
		margin-top 15px

		@media tablet
			margin-top 10px
			font-size 22px

		@media mobile
			font-size 18px

		&_min
			max-width 200px

			@media tablet
				max-width 100%

			@media mobile
				margin-x auto
				max-width 300px

// @stylint off
[class*='ymaps-2-1'][class*='-ground-pane']
	filter grayscale(100%)

[class*='ymaps-2-1'][class*='-events-pane']
	&::after
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		pointer-events none
		content ''
		background-image linear-gradient(204deg, rgba(white, 0), white)

		@media tablet
			background-image linear-gradient(193deg, rgba(white, 0), white)

.interactive-map__iframe_contact [class*='ymaps-2-1'][class*='-events-pane']
	&::after
		background-image linear-gradient(268deg, rgba(white, 0), white)

		@media tablet
			background-image linear-gradient(194deg, rgba(white, 0), white)

		@media mobile
			background-image linear-gradient(to bottom, rgba(white, 0), white)

[class*='ymaps-2-1'][class*='-places-pane']
	z-index 9999 !important // magic number
