.visually-hidden
	position absolute
	overflow hidden
	clip rect(0 0 0 0)
	margin -1px
	padding 0
	width 1px
	height 1px
	border 0

.defaultHover
	opacity 1
	transition opacity trDur trFunc
	will-change opacity

	&:hover,
	&:focus,
	&:active
		opacity .6

.text32Medium
	font-size 32px
	font-weight 500
	line-height 1.41
	letter-spacing -.8px

	@media mobile
		font-size 22px
		line-height 1.27
		letter-spacing -.3px

.text30Bold
	font-size 30px
	font-weight 600
	line-height 1.13

.text22Medium
	font-size 22px
	font-weight 500
	line-height 1.55
	letter-spacing -.3px

.text22Regular
	font-size 22px
	font-weight 400
	line-height 1.456
	letter-spacing -.2px

	@media mobile
		font-size 18px
		line-height 1.44
		letter-spacing -.4px

.text18Medium,
.text18Regular
	font-size 18px
	line-height 1.44
	letter-spacing -.4px

.text18Medium
	font-weight 500

.text18Regular
	font-weight 400

.text15Bold,
.text15Medium,
.text15Regular
	font-size 15px
	line-height 1.35
	letter-spacing normal

.text15Bold
	font-weight 600

.text15Medium
	font-weight 500

.text15Regular
	font-weight 400

.capital-letter
	padding-right 22px
	float left
	font-family Playfair
	font-size 74px
	line-height .8

	@media tablet
		padding 0
		float none
		font-family Avenir
		font-size 22px
		line-height 1.456

	@media mobile
		font-size 18px
		line-height 1.456

.card-fade
	animation cardFadeIn trDur*2 cubic-bezier(0, 0, 0.15, 0.93)

@keyframes cardFadeIn
	from
		opacity 0
		transform translateY(-15px)
	to
		opacity 1
		transform translateY(0)
