.divided-text
	&__content
		margin-top 40px
		display flex

		@media tablet
			margin-top 30px
			flex-direction column

	&__text
		@extends .text22Regular
		margin-right 56px
		max-width 580px

		@media tablet
			margin-right 0
			max-width none

	&__description
		position relative
		z-index 1
		padding-y 14px
		padding-left 118px
		flex-shrink 0
		max-width 376px

		// empty line fix linter
		font-family Cormorant // TODO: Проблема со шрифтом
		font-size 28px
		font-weight 700
		font-style italic
		line-height 1.2
		border-left 1px solid rgba(black, .4)

		@media tablet
			margin-top 72px
			margin-left 40px
			padding-y 22px
			padding-left 70px
			max-width none

		@media mobile
			margin-top 30px
			margin-left 0
			padding-x 30px
			padding-top 50px
			border-top 1px solid rgba(black, .4)
			border-left none

		// круг на фоне
		&::before
			position absolute
			z-index -1
			top 0
			left 88px
			width 72px
			height 72px
			content ''
			background-color rgba(grayLight, .3)
			border-radius 100%

			@media tablet
				top 4px
				left 44px

			@media mobile
				top 30px
				left 0
