.radio-button
	position relative
	display inline-flex

	&__input
		position absolute
		top 2px
		left 3px
		opacity 0

	&__input:not(:checked) + &__label::after
			opacity 0

	&__input:hover + &__label::before,
	&__input:focus + &__label::before,
	&__input:active + &__label::before
		border-color gray

	&__label
		@extends .text15Regular
		position relative
		padding-left 30px
		cursor pointer

		&::before,
		&::after
			position absolute
			content ''
			border-radius 100%

		// бордер radio-button
		&::before
			size 20px
			top 0
			left 0
			box-sizing border-box
			background-color white
			border 1px solid grayLight
			transition border-color trDur trFunc
			will-change border-color

		// элемент checked
		&::after
			size 10px
			top 5px
			left 5px
			background-color black
			transition opacity trDur trFunc
			will-change opacity

	&_color_green &__label::after
		background-color green
