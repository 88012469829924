.page
	&_no-scroll
		position fixed
		overflow hidden
		width 100vw
		height 100vh

	&_mobile-menu
		@media tablet
			position fixed
			overflow hidden
			width 100vw
			height 100vh
