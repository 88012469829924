.link
	@extends .text15Medium
	padding 0
	display inline
	text-decoration none
	color inherit
	background-color transparent
	border none
	opacity .6
	transition opacity trDur trFunc
	will-change opacity

	&:hover,
	&:focus
		opacity .3

	&:active
		opacity 1

	&_icon
		display inline-flex
		align-items center
		font-weight 600

	&__icon
		position relative // ie11 fix
		margin-left 5px
		line-height 0

		&_left
			margin-right 5px
			margin-left 0
			order -1

	&_theme_green
		@extends .text15Bold
		color green
		opacity 1
		transition-duration trDur
		transition-timing-function trFunc
		transition-property opacity, color
		will-change opacity, color

		&:hover,
		&:focus,
		&:active
			color greenEucalyptus

	&__text
		position relative // ie11 fix
