/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading {
  background-image: url("../../images/fancybox/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../../images/fancybox/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 38px;
	height: 38px;
	cursor: pointer;
	z-index: 8040;
	background-image: url('../../images/modal-close.svg');
	background-size: cover;
	background-color: white;
	border-radius: 50%;
	transition: opacity 0.3s linear;
	will-change: transition; }

.fancybox-close:hover,
.fancybox-prev:hover button,
.fancybox-next:hover button {
	opacity: 0.8;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../../images/fancybox/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav button {
  position: absolute;
  top: 50%;
  width: 38px;
  height: 38px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  border: none;
  border-radius: 50%;
  background: white url('../../images/modal-arrow.svg') no-repeat 40% 50%;
  background-size: 40%;
  transition: opacity 0.3s linear;
  will-change: transition; }

.fancybox-prev button {
  left: 10px; }

.fancybox-next button {
  right: 10px;
  transform: rotate(180deg); }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: visible !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("../../images/fancybox/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading {
    background-image: url("../../images/fancybox/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("../../images/fancybox/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }
