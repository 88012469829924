.columned-text
	margin-x -15px
	display flex
	flex-wrap wrap

	&__title
		margin-bottom 50px
		padding-x 15px
		width 100%

		@media tablet
			margin-bottom 40px

		@media mobile
			margin-bottom 30px

	&__text
		@extends .text22Regular
		padding-x 15px
		width 50%

		@media tablet
			width 100%

		& + &
			@media tablet
				margin-top 45px

			@media mobile
				margin-top 46px
