.preloader
	position fixed
	top 0
	left 0
	display flex
	align-items center
	justify-content center
	width 100%
	height 100%
	z-index 1000
	background-color rgba(black, .6)

	&_loaded
		pointer-events none
		animation preloader-loaded 1s ease-out
		animation-fill-mode forwards

	&__image
		animation preloader 6s ease-in-out infinite


@keyframes preloader
	from
		opacity 0
	50%
		opacity 1
	to
		opacity 0

@keyframes preloader-loaded
	from
		visibility visible
		opacity 1
	to
		visibility hidden
		opacity 0
