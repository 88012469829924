.novice-page
	&__info-section
		margin-y 100px

		@media mobile
			margin-top 40px
			margin-bottom 60px

	&__tips-section
		margin-bottom 80px
		padding-y 110px
		text-align center
		background-color whiteSmoke

		@media tablet
			margin-bottom 30px
			padding-top 101px
			padding-bottom 93px

		@media mobile
			margin-bottom 51px
			padding-y 50px

	&__tips-section-inner
		margin-x auto
		display flex
		flex-direction column
		align-items center
		max-width 946px

	&__tips-section-title
		margin-bottom 40px

		@media mobile
			margin-bottom 30px

	&__tips-section-text
		@extends .text22Medium
		max-width 100% // ie11 fix

		@media mobile
			font-size 18px
			letter-spacing -.4px

	&__bottom-section
		margin-x auto
		margin-top 60px
		margin-bottom 100px
		max-width 750px

		@media tablet
			margin-top 40px

		@media mobile
			margin-bottom 50px

	&__bottom-section-text
		@extends .text22Regular
		
		&:not(:last-child)
			margin-bottom 40px

			@media tablet
				margin-bottom 30px

