.place-section
	padding-top 110px
	padding-bottom 117px
	background-color white

	@media tablet
		padding-top 30px
		padding-bottom 100px

	@media mobile
		padding-top 15px
		padding-bottom 60px

	&__inner
		display flex
		align-items center
		justify-content space-between

		@media tablet
			flex-direction column-reverse
			align-items stretch

	&__text-block
		max-width 555px

		@media tablet
			max-width none

	&__slider
		size 487px
		flex-shrink 0

		@media tablet
			margin-x -30px
			margin-bottom 34px
			padding-x 30px
			width auto
			height 709px

		@media mobile
			margin-x -15px
			margin-bottom 40px
			padding-x 0
			height auto

	&__button-wrapper
		display inline-flex

		@media tablet
			width 290px

	&__text
		@extends .text22Regular
		margin-top 38px
		margin-bottom 50px

		@media tablet
			margin-top 40px

		@media mobile
			margin-top 30px
			margin-bottom 40px

