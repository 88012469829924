.container
	margin-x auto
	padding-x 50px
	max-width 1240px

	@media tablet
		padding-x 30px

	@media mobile
		padding-x 15px
