buttonBlock = '.button'
.button
	padding 14px 26px
	display inline-flex
	align-items center
	justify-content center
	min-width 204px
	font-family Avenir
	font-size 15px
	font-weight 600
	line-height 1.37
	white-space nowrap
	cursor pointer
	// empty line fix linter

	text-decoration none
	user-select none
	color white
	background-color black
	border none
	transition-duration trDur
	transition-timing-function trFunc
	transition-property background-color transform
	will-change background-color transform

	// iOS flex fix
	&::before,
	&::after
		content ''
		flex 1 0 auto

	&_animate
		animation buttonClick trDur trFunc

	&:hover:not(:disabled),
	&:focus,
	&:active,
	&:disabled,
	&{buttonBlock}_loading
		background-color rgba(black, .2)

	&_size_small
		padding 10px 17px
		min-width 186px

	&_size_small &__icon
		width 12px
		height 12px

		&:not(:last-child)
			margin-right 7px

	&_full-width
		width 100%

	&_theme_green
		background-color green

		&:hover:not(:disabled),
		&:focus,
		&:active,
		&{buttonBlock}_loading
			background-color greenEucalyptus

	&_theme_whiteGreen
		color green
		background-color white

		&:hover:not(:disabled),
		&:focus,
		&:active,
		&{buttonBlock}_loading
			// TODO Узнать какой должен быть цвет
			background-color greenEucalyptus

	&_theme_greenHover
		&:hover:not(:disabled),
		&:focus,
		&:active,
		&{buttonBlock}_loading
			background-color green

	&_theme_fb
		padding-x 24px
		background-color blueFb

		&:hover:not(:disabled),
		&:focus,
		&:active
			background-color blueMariner

	&_theme_vk
		padding-x 24px
		background-color blueAirForce

		&:hover:not(:disabled),
		&:focus,
		&:active
			background-color blueSanMarino

	&_loading &__icon
		animation rotate 1s linear infinite

	&__icon
		position relative // ie11 fix
		display flex
		width 20px
		height 20px
		will-change transform

		&:not(:last-child)
			margin-right 12px

	&__text
		position relative // ie11 fix


@keyframes rotate
	from
		transform rotate(0)
	to
		transform rotate(360deg)

@keyframes buttonClick
	from
		transform scale(1)
	50%
		transform scale(.95)
	to
		transform scale(1)
