.contact-card
	padding 25px 15px
	display flex
	flex-direction column
	align-items center
	text-align center
	background-color whiteSmoke

	&__link
		@extends .text15Regular
		@extends .defaultHover
		margin-top 5px
		text-decoration none
		color inherit

	&__note
		margin-top 15px
		width 100%
		font-size 12px
		line-height normal
		letter-spacing normal
		opacity .6
