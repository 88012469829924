headerAndFooterHeight = 221px // set header and footer height on mobile for wrapper min-height calc() method

.profile-page
	&__inner
		padding-top 47px
		padding-bottom 140px
		display flex
		overflow hidden

		@media tablet
			margin-x -30px
			padding-top 0
			padding-bottom 100px
			flex-direction column

		@media mobile
			margin-x -15px
			padding-bottom 50px
			min-height 'calc(100vh - %s)' % headerAndFooterHeight

	&__menu
		margin-right 30px
		width 166px
		background-color white
		flex-shrink 0

		@media tablet
			position fixed
			z-index 1
			top 85px
			right 0
			left 0
			margin-right 0
			width auto

		@media mobile
			position static

	&__content-wrapper
		position relative
		width calc(100% - 196px) // menu size and margin

		@media tablet
			margin-top 100px
			width 100%

		@media mobile
			margin-top 40px
			display flex
			align-items center
			justify-content center

	&__content
		position absolute
		top 0
		left 0
		margin-top 2px
		visibility hidden
		opacity 0
		transition opacity trDur trFunc
		will-change opacity

		@media tablet
			margin-top 0
			padding-x 30px

		@media mobile
			padding-x 15px

		&_table-view
			@media mobile
				padding-x 0
				width 100%

		&_visible
			position static
			visibility visible
			opacity 1

	& &__title
		margin-bottom 40px

		@media tablet
			margin-bottom 50px
			font-size 58px
			line-height 1.18

		@media mobile
			margin-bottom 40px
			font-size 42px
			line-height 1
			text-align center

	&__table-wrapper
		display flex

	&__no-requests-wrapper
		margin-x auto
		padding-y 80px
		max-width 550px
		text-align center

		@media tablet
			padding-top 170px
			padding-bottom 120px

		@media mobile
			padding-top 10px
			padding-bottom 25px
			max-width 400px
