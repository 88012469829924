.subscribe-card
	@extends .text18Regular
	padding-x 30px
	padding-top 72px
	padding-bottom 45px
	min-height 100%
	text-align center
	background-color white
	border 1px solid grayDark

	@media tablet
		padding-x 25px
		padding-bottom 25px

	@media mobile
		padding-x 5px
		padding-top 82px
		padding-bottom 41px
		min-height 0

	&__inner
		margin-x auto
		max-width 250px

		@media tablet
			max-width 290px

		@media mobile
			max-width 233px

	&__mail-icon
		margin-bottom 17px
		display inline-flex
		width 45px
		height 31px
		background-image url(/images/mail.svg)

	&__text
		margin-top 11px
		margin-bottom 54px
		line-height 1.45

		@media tablet
			margin-bottom 74px

		@media mobile
			margin-top 12px
			margin-bottom 43px
