.feature-card
	padding-x 60px
	display flex
	flex-direction column
	justify-content center
	width 100%
	height 100%
	text-align center
	border 1px solid rgba(grayDark, .4)

	@media tablet
		padding-x 40px

	@media mobile
		padding-x 20px

	&_img
		padding-x 0
		border none

		@media tablet
			padding-x 0

		@media mobile
			padding-x 0

	&__image
		width 100%
		height 100%

	&__category
		@extends .text15Bold
		margin-bottom 5px

	& &__title
		@media mobile
			font-size 30px
