.modal-wrapper
	position fixed
	z-index 1000
	top 0
	left 0
	display flex
	align-items flex-start
	size 100%
	overflow-y auto
	background-color rgba(black, .6)

	@media mobile
		padding-x 15px
