.ui-kit
	padding 60px
	min-height 100vh
	background-color lightgrey

	&__group
		margin-bottom 50px
		display flex
		flex-wrap wrap
		width 100%

		&_fullWidth
			display flex
			flex-direction column
			flex-wrap nowrap

	&__group-title
		margin-bottom 30px
		width 100%
		font-size 28px

	&__item
		margin-right 50px
		margin-bottom 20px

	&__title
		margin-bottom 15px
		font-size 22px

	&__code
		margin-bottom 20px
		padding 10px
		display inline-block
		font-size 18px
		word-break break-all
		background-color white
		border 2px solid black
		border-radius 6px

	&__result
		&_slider-wrapper
			width 487px

			@media mobile
				width auto

	&__toc
		margin-bottom 50px
		padding 20px
		display flex
		flex-direction column
		align-items flex-start
		max-width 450px
		background-color white
		border 2px solid black

	&__tocLink
		margin-bottom 10px
		font-size 18px
