.contact-list
	display flex
	justify-content space-between

	@media tablet
		margin-x auto
		max-width 750px
		flex-wrap wrap
		justify-content space-around

	@media mobile
		flex-direction column
		justify-content flex-start

	&__contact
		padding-x 10px
		display flex
		flex-direction column
		align-items center
		width 260px
		text-align center

		@media tablet
			padding-bottom 46px

		@media mobile
			padding-y 30px
			width 100%

		&:not(:last-child)
			@media mobile
				border-bottom 1px solid rgba(black, .2)

	&__avatar
		overflow hidden
		size 130px
		border-radius 50%

	&__ava-image
		size 100%
		border-radius 50%

	&__name
		@extends .text18Medium
		margin-top 30px
		max-width 100%
		letter-spacing -.01em

		@media tablet
			margin-top 25px
			font-size 25px
			line-height 1.05

		@media mobile
			margin-top 27px
			max-width 260px
			font-size 22px

	&__post
		@extends .text15Regular
		margin-top 5px
		max-width 100%

		@media tablet
			margin-top 10px
			font-size 18px

		@media mobile
			margin-top 12px
			max-width 260px

	&__phone
		@extends .text18Medium
		margin-top 10px
		text-decoration none
		color black
		transition opacity trDur trFunc
		will-change opacity

		&:hover
			opacity .8

	&__skype
		@extends .text18Medium
		margin-top 13px
		display flex
		text-decoration none
		letter-spacing -.01em
		color black
		opacity .4
		transition opacity trDur trFunc
		will-change opacity

		&:hover
			opacity .8

	&__skype-icon
		margin-right 9px
		size 21px
