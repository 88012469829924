.place-slider
	&__inner-wrapper
		display flex
		flex-direction column
		align-items center

		@media tablet
			margin-x -30px

		@media mobile
			margin-x -15px

	&__slide
		display flex
		width 300px
		height auto

	&__container
		width 100%

	&__wrapper
		margin-top 95px
		margin-bottom 73px
		color black
		justify-content space-between
		align-items stretch

		@media tablet
			margin-y 50px

		@media mobile
			margin-y 30px

	&__pagination
		display none

		@media mobile
			position static
			margin-bottom 31px
			display block

	&__image
		width 300px
		height 378px
		background-position center center
		background-size cover

	// @stylint off
	.swiper-pagination-bullet
		margin 0 7px !important
		size 10px

		&-active
			background-color black !important
