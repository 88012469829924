.modals-page
	&__code
		margin-bottom 20px
		padding 10px
		display inline-block
		font-size 18px
		background-color white
		border 2px solid black
		border-radius 6px

	&__button
		position fixed
		z-index 9999
		left 50%
		bottom 2%
		transform translateX(-50%)

	&__modal
		display none

		&_active
			display block
