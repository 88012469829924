.lang-change
	@extends .text15Bold
	position relative
	color inherit
	text-transform uppercase

	// разделитель
	&__handlerWrap::after
		content ' /'

	&__handler
		text-decoration none
		cursor pointer
		color inherit
		opacity .4
		transition opacity trDur trFunc
		will-change opacity

		&:hover,
		&:focus,
		&:active,
		&_active
			opacity 1

		&_active
			cursor default
