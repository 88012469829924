.title
	display block
	font-family Playfair

	&_size_1
		font-size 78px
		line-height 1

		@media mobile
			font-size 42px
			line-height 1.17
			letter-spacing normal

	&_size_2
		font-size 58px
		line-height 1.14
		letter-spacing -.5px

		@media mobile
			font-size 42px
			line-height 1.17
			letter-spacing normal

		&^[0]_custom
			margin-bottom 70px

			@media tablet
				margin-bottom 40px

			@media mobile
				margin-bottom 30px

	&_size_3
		font-size 42px
		line-height 1.24

		&^[0]_custom
			margin-bottom 20px

	&_size_4
		font-size 32px
		line-height 1.19
		letter-spacing .4px

		&^[0]_custom
			margin-bottom 20px

			@media mobile
				margin-bottom 10px

	&_drop_
		&desktop
			line-height 1

		&tablet
			@media tablet
				line-height 1

		&mobile
			@media mobile
				line-height 1

	&_align
		&_center
			text-align center
