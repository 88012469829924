.text
	&::after
		content ''
		display table
		clear both

	&_intro
		margin-y 20px
		font-size 22px
		font-weight 400
		line-height 1.45
		letter-spacing -.2px

		@media tablet
			font-size 18px

		@media mobile
			line-height 1.44
			letter-spacing -.4px

		&::first-letter
			@extends .capital-letter

	&_note
		@extends .text15Regular
		margin-y 20px

		@media mobile
			font-size 13px

	&_size_22
		@extends .text22Regular

	&_size_18
		@extends .text18Regular

		@media tablet
			font-size 15px
			line-height 1.6
			letter-spacing -.3px

	&_margins
		&_big
			margin-top 70px
			margin-bottom 100px

			@media tablet
				margin-bottom 70px

			@media mobile
				margin-top 50px
				margin-bottom 60px

		&_small
			margin-top 30px
			margin-bottom 50px

			@media tablet
				margin-top 25px
				margin-bottom 30px

.bold
	font-weight bold

.italic
	font-style italic

.underline
	text-decoration underline

.colorized
	color green
	font-weight 600

.floated
	float left
	margin 15px 25px 20px 0

.list
	@extends .text22Regular

	li
		position relative
		margin-bottom 20px
		padding-left 43px

		&::before
			position absolute

	&_ordered
		margin-top 15px
		counter-reset count

		li
			@media mobile
				padding-left 37px

			&::before
				top -4px
				left 8px
				font-family Playfair
				font-size 30px
				font-weight bold
				line-height 1
				counter-increment count
				content counter(count) '.'

				@media mobile
					left 0

	&_unordered
		margin-top 20px

		li
			@media tablet
				padding-left 30px

			@media mobile
				padding-left 21px

			&::before
				top 10px
				left 13px
				size 10px
				border-radius 50%
				background-color black
				content ''

				@media tablet
					left 0

				@media mobile
					top 8px
					size 8px

