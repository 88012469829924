.offers-section
	padding-top 133px
	padding-bottom 90px
	text-align center
	background-color whiteSmoke

	@media tablet
		padding-top 100px
		padding-bottom 65px

	@media mobile
		padding-top 50px
		padding-bottom 47px

	&__title
		@media tablet
			margin-x auto
			max-width 440px

	&__text
		@extends .text22Medium
		margin-top 52px
		margin-bottom 80px
		padding-left 15px

		@media tablet
			margin-top 40px
			margin-bottom 50px
			padding-left  0
			line-height 29px

		@media mobile
			margin-top 30px
			margin-bottom 19px
			font-size 18px
			line-height 1.45
			text-align left

	&__signature
		margin-x auto
		width 151px
		height 80px

		@media mobile
			width 114px
			height 55px
