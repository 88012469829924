.academy-page
	&__about-section
		padding-y 100px

	&__school-section
		padding-top 60px
		padding-bottom 100px

		@media tablet
			padding-top 65px
			padding-bottom 105px

		@media mobile
			padding-top 40px
			padding-bottom 50px

	&__children-school-img
		height 500px
		background-repeat no-repeat
		background-position 0 50%
		background-size cover

		@media tablet
			margin-x -30px

		@media mobile
			margin-x -15px
			height 367px
			background-position 25% 50%

	&__coaches-section
		padding-top 104px
		padding-bottom 110px

		@media mobile
			padding-top 50px
			padding-bottom 57px

	&__slider-section
		padding-y 140px
		background-color whiteSmoke

		@media tablet
			padding-y 100px

		@media mobile
			padding-top 45px
			padding-bottom 50px
