.data-handling-page
	margin-x auto
	margin-y 100px
	max-width 750px


	@media tablet
		margin-top 50px
		margin-bottom 70px
		max-width 710px

	@media mobile
		margin-top 35px
		padding-x 16px


	&__text
		@extends .text22Medium
		margin-top 60px

		@media tablet
			margin-top 50px
			font-size 18px

		@media mobile
			margin-top 40px

	&__list
		@extends .text22Regular
		margin-top 70px
		counter-reset listStyle

		@media tablet
			margin-top 60px
			font-size 18px

		@media mobile
			margin-top 30px

	&__list-level-2
		margin-top 30px
		counter-reset subListStyle

	&__list-item
		// list-style для элементов списка 1 уровня
		&::before
			content counter(listStyle) '. '
			counter-increment listStyle

		&:not(:last-child)
			margin-bottom 30px

	&__list-item-level-2
		// list-style для элементов списка 2 уровня
		&::before
			content counter(listStyle) '.' counter(subListStyle) '. '
			counter-increment subListStyle

		&:not(:last-child)
			margin-bottom 30px

	// list-style для элементов списка 3 уровня
	&__list-item-level-3::before
		content '— '
