.mobile-menu
	padding 40px
	display flex
	flex-direction column
	width 100%
	min-height 100%
	background-color black

	@media mobile
		padding 16px

	&__nav
		@extends .text22Medium
		margin auto
		text-align center

		@media mobile
			font-size 15px

	&__nav-item
		display flex
		flex-direction column
		align-items center

	&__nav-item
		margin-bottom 30px

		@media mobile
			margin-bottom 15px

	&__nav-link
		display inline-flex
		white-space nowrap
		text-decoration none
		color inherit

		&_active
			color green

	&__footer
		margin-x auto
		display flex
		flex-direction column
		width 474px
		align-items center

		@media mobile
			width 100%

		// серый горизонтальный разделитель
		&::before
			margin-top 35px
			margin-bottom 40px
			width calc(100vw - 100px)
			height 1px
			content ''
			background-color rgba(white, .2)

			@media mobile
				margin-y 15px
				width 100%

	&__phone-link
		@extends .text22Medium
		margin-bottom 40px
		text-decoration none
		color inherit

		@media mobile
			margin-bottom 15px
			font-size 15px

	&__callback
		margin-bottom 30px

		@media mobile
			margin-bottom 20px

	&__info
		display flex
		width 100%
		justify-content space-between
		align-items center
