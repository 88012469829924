.news-page
	padding-top 48px
	padding-bottom 100px
	text-align center

	@media mobile
		padding-top 20px
		padding-bottom 50px

	&__nav
		@extends .text18Medium
		margin-top 41px
		display inline-flex

		@media mobile
			margin-top 23px
			font-size 15px

	&__nav-title
		margin-right 20px
		letter-spacing .02em

		@media mobile
			margin-right 15px

	&__years
		display flex

	&__nav-year:not(:last-child)
		margin-right 8px

	&__nav-button
		text-decoration none
		color black
		background none
		border none
		opacity .4
		transition opacity trDur trFunc
		will-change opacity

		&:hover
			opacity 1

		&_state_active
			opacity 1

	&__nav-inner
		position relative
		top 0
		left 0

	&__content
		margin-x -15px
		margin-top 49px
		margin-bottom 40px
		display flex
		flex-wrap wrap
		text-align left

		@media tablet
			margin-bottom 20px

		@media mobile
			margin-x 0
			margin-top 25px
			margin-bottom 25px

	&__item
		padding-x 15px
		padding-bottom 30px
		width 33.3333%

		&_hide
			@media tablet
				display none

			@media mobile
				display block

		@media tablet
			width 50%

		@media mobile
			padding-x 0
			padding-bottom 15px
			width 100%

	&__button
		margin-x auto
		width 203px

		@media tablet
			width 220px

		@media mobile
			width 233px
