.checkbox
	position relative
	display inline-flex

	&__input
		position absolute
		opacity 0

	&__input:not(:checked) + &__label &__checked
		color grayLight
		opacity 0

	&__input:hover:not(:checked) + &__label &__checked,
	&__input:focus + &__label &__checked
		opacity .4

	&__input:checked + &__label &__checked
		opacity 1

	&__input:hover + &__label::before,
	&__input:focus + &__label::before,
	&__input:active + &__label::before
		border-color gray

	&__label
		@extends .text15Regular
		position relative
		padding-left 30px
		cursor pointer

		// бордер
		&::before
			size 20px
			position absolute
			top 50%
			left 0
			box-sizing border-box
			content ''
			background-color white
			border 1px solid grayLight
			border-radius 4px
			transform translateY(-50%)
			transition border-color trDur trFunc
			will-change border-color

	&__checked
		position absolute
		top calc(50% - 1px)
		left 3px
		width 14px
		height 14px
		color green
		transform translateY(-50%)
		transition-duration trDur
		transition-timing-function trFunc
		transition-property color, opacity
		will-change color, opacity
