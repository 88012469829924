.info-section
	display flex
	flex-direction column
	align-items center
	text-align center

	@media mobile
		text-align left

	&__list
		margin-x -15px
		margin-bottom 80px
		display flex
		flex-wrap wrap
		max-width calc(100% + 30px) // ie11 fix

		@media tablet
			margin 0
			max-width none

		@media mobile
			margin-bottom 25px

	&__item
		padding-x 15px
		display flex
		flex-direction column
		align-items center
		width 25%

		@media tablet
			margin-bottom 71px
			padding-x 0
			width 50%

		@media mobile
			margin-bottom 25px
			align-items flex-start
			width 100%

		&:not(:last-child)
			@media mobile
				margin-bottom 25px

	&__itemText
		@extends .text18Regular
		margin-top 10px
		max-width 263px

		@media mobile
			margin-top 0
			max-width none
