.golf-club-page
	&__intro
		margin-bottom 50px

		@media tablet
			margin-bottom 0

	&__holes-slider
		margin-top 75px
		margin-bottom 200px

		@media tablet
			margin-top 100px
			margin-bottom 80px

		@media mobile
			margin-y 50px

	&__tournament-slider
		margin-bottom 110px

		@media tablet
			margin-bottom 100px

		@media mobile
			margin-bottom 60px

	&__slider-content
		margin-bottom 155px
		display flex
		justify-content space-between
		align-items center
		width 100%

		@media tablet
			margin-bottom 100px
			flex-direction column

		@media mobile
			margin-bottom 50px

	&__content-column
		width 100%

	&__content-text
		margin-top 26px
		margin-bottom 50px

		@media tablet
			margin-top 40px

		@media mobile
			margin-top 30px
			margin-bottom 40px
			font-size 18px

	&__square-slider
		margin-right 98px
		size 487px
		flex-shrink 0

		@media tablet
			margin-right 0
			margin-bottom 50px
			width 100%
			height 708px
			order -1

		@media mobile
			margin-x -15px
			margin-bottom 40px
			width calc(100% + 30px)
			height 378px

		&_align_right
			margin-right 0
			margin-left 98px

			@media tablet
				margin-x auto

			@media mobile
				margin-x -15px
