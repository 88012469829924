.coaches-section
	display flex
	flex-direction column
	align-items center
	text-align center

	&__title
		margin-top 40px
		margin-bottom 45px

		@media tablet
			margin-bottom 40px

		@media mobile
			margin-bottom 31px

	&__text
		margin-x auto
		max-width 950px
		font-family CormorantGaramond
		font-size 34px
		font-weight 500
		font-style italic
		line-height normal
		letter-spacing normal

		@media tablet
			max-width 640px
			font-size 28px

		@media mobile
			font-size 20px

	&__images
		position relative
		size 80px

		@media tablet
			size 105px

		@media mobile
			size 80px

	&__img-layer
		position absolute
		top 50%
		left 50%
		display flex
		justify-content space-between
		transform translate(-50%, -50%)

		&_second
			width 140px
			height 60px

			@media tablet
				width 178px
				height 76px

			@media mobile
				width 140px
				height 60px

		&_third
			width 180px
			height 40px

			@media tablet
				width 228px
				height 51px

			@media mobile
				width 180px
				height 40px

	&__img
		position relative
		display block
		width auto
		height 100%
