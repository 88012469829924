.tournament-header
	position relative
	z-index 0
	text-align center
	color white

	&__inner
		position relative
		padding-top 80px
		padding-bottom 77px
		display flex
		justify-content center
		background-color green

		@media tablet
			padding-top 159px
			padding-bottom 169px

		@media mobile
			padding 68px 22px 40px

		// круги на фоне
		&::before,
		&::after
			position absolute
			width 183px
			height 284px
			content ''
			background-repeat no-repeat
			background-position 50% 50%
			background-size contain

			@media mobile
				width 138px
				height 214px

		&::before
			top 30px
			right 27px
			background-image url('/images/tournaments-page/header-top.svg')

			@media tablet
				top 28px
				right 28px

			@media mobile
				top 16px
				right 16px

		&::after
			left 27px
			bottom 28px
			background-image url('/images/tournaments-page/header-bottom.svg')

			@media tablet
				left 28px
				bottom 28px

			@media mobile
				left 16px
				bottom 16px

	&__wrapper
		position relative
		z-index 1
		display flex
		flex-direction column
		align-items center
		width 655px

		@media tablet
			width 555px

		@media mobile
			width 310px

	&__title
		position relative
		margin-bottom 41px
		padding-bottom 35px
		width 100%
		line-height 1.06

		@media tablet
			padding-bottom 63px

		@media mobile
			margin-bottom 29px
			padding-bottom 30px

		// горизонтальная черта
		&::after
			position absolute
			left 50%
			bottom 0
			width 555px
			height 1px
			content ''
			background-color rgba(white, .25)
			transform translateX(-50%)

			@media tablet
				left 0
				width 100%
				transform none

	&__list
		margin-bottom 63px
		padding-x 29px
		display flex
		justify-content center
		width 100%
		line-height 0

		@media tablet
			margin-bottom 52px

		@media mobile
			margin-bottom 24px
			padding-x 0
			flex-wrap wrap

	&__item
		&:not(:last-child)
			margin-right 50px

			@media mobile
				margin-right 0

		@media mobile
			margin-bottom 30px
			flex-basis 50%
