// TODO: адаптив уточняется
.gallery-card
	@extends .card-fade
	position relative
	display block
	height 100%
	text-decoration none
	color inherit
	transition transform trDur trFunc
	will-change transform, opacity

	&:hover
		transform translateY(-5px)

		&::after
			opacity 1

	// тень при ховере
	&::after
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		content ''
		opacity 0
		box-shadow 0 9px 16px 0 rgba(black, .08)
		transition opacity trDur trFunc
		will-change opacity

	&__image
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		background-position center center
		background-size cover

	&__content
		position relative
		padding 25px
		display flex
		flex-direction column
		height 100%
		//background-image linear-gradient(224deg, rgba(black, 0) 40%, black)
		justify-content flex-end

		@media mobile
			padding 20px

	&__date
		@extends .text15Bold

	& &__title
		line-height 1.4

		@media tablet
			max-width 320px

		@media mobile
			max-width none

	&__amount-content
		margin-top 15px
		display flex
		align-items center

	&__icon
		margin-left 12px
		width 22px
		height 14px

	&__amount
		@extends .text18Regular
