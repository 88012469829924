.head-section
	position relative
	padding-top 274px
	padding-bottom 186px
	color white
	background-color greenVeryDark
	background-repeat no-repeat
	background-position 50% 50%
	background-size cover
	retinaImage('main-page/head2-bg', 'jpg')

	@media tablet
		padding-top 332px
		padding-bottom 322px

	@media mobile
		padding-top 132px
		padding-bottom 129px

	// overlay на фоне
	&::before
		position absolute
		top 0
		left 0
		size 100%
		content ''
		background-color rgba(black, .4)

	&__inner
		position relative
		z-index 1
		margin-x auto
		max-width 395px
		text-align center

		@media tablet
			max-width 552px

	&__small-title
		@extends .text22Medium
		margin-bottom -3px
		line-height 1

		@media tablet
			margin-bottom 2px
			font-size 25px
			letter-spacing normal

		@media mobile
			font-size 18px
			letter-spacing -.4px

	&__title-pestovo
		margin-bottom 35px
		margin-left 15px
		width 369px
		height 79px
		font-size 0
		line-height 0
		background url('/images/main-page/pestovo.svg') 50% 50% no-repeat
		background-size contain

		@media tablet
			margin-x auto
			margin-bottom 42px

		@media mobile
			margin-bottom 30px
			width 294px
			height 64px

	&__description
		@extends .text18Regular

		@media tablet
			font-size 22px
			line-height 1.46

		@media mobile
			font-size 18px
			line-height 1.5
			letter-spacing -.3px
