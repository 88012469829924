.quote
	position relative
	padding-top 85px
	display block
	font-family Cormorant
	font-size 28px
	font-weight 700
	font-style italic
	line-height 1.22
	text-align center
	color rgba(black, .5)

	&::before
		position absolute
		top 0
		left 50%
		font-size 105px
		content '“'
		color rgba(black, .5)
		transform translateX(-50%)

	&_custom
		margin-top 47px
		margin-bottom 70px

		@media tablet
			margin-top 37px

		@media mobile
			margin-top 17px
			margin-bottom 50px
