.cards-section
	padding-top 30px
	padding-bottom 100px

	@media mobile
		padding-top 16px
		padding-bottom 50px

	&__inner
		display flex
		flex-direction column

	&__list
		margin-x -15px
		margin-bottom 40px
		display flex
		flex-wrap wrap
		max-width calc(100% + 30px) // ie11 fix

		@media tablet
			margin-bottom 20px

		@media mobile
			margin-bottom 24px
			flex-direction column

	&__card
		margin-bottom 30px
		padding-x 15px
		width 33.3333%
		height 500px

		@media tablet
			width 50%

		@media mobile
			margin-bottom 16px
			width 100%

	&__list_odd &__card:last-child
			@media tablet
				display none

			@media mobile
				display block
	&__button
		align-self center
