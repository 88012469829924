.membership-section
	// TODO: ждём материалы по анимации кругов
	padding-y 100px
	text-align center
	color white
	background-color green

	@media tablet
		padding-bottom 110px

	@media mobile
		padding-y 50px

	&__inner
		display flex
		flex-direction column
		align-items center

	&__icon
		@media mobile
			width 44px
			height 34px

	&__text
		@extends .text32Medium
		margin-top 50px
		margin-x auto
		max-width 900px
		margin-bottom 60px

		@media tablet
			margin-bottom 50px

		@media mobile
			margin-top 30px
			margin-bottom 40px
