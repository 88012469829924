.gallery-page
	&__inner
		margin-top 50px
		margin-bottom 100px
		display flex
		flex-direction column
		align-items center
		width 100%

		@media tablet
			margin-top 70px

		@media mobile
			margin-top 40px
			margin-bottom 50px


	&__album-list
		margin-top 90px
		margin-bottom 40px
		display flex
		flex-wrap wrap
		justify-content space-between
		width 100%
		color white

		@media tablet
			margin-top 70px
			margin-bottom 20px
			flex-direction column
			align-items center
			width 100%

		@media mobile
			margin-top 32px
			margin-bottom 24px

	&__album
		margin-bottom 30px
		width 555px
		height 400px

		@media tablet
			width 100%
			max-width 708px

		@media mobile
			margin-bottom 16px
			max-width 343px
