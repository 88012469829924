.video
	position relative
	margin-x auto
	max-width 946px
	max-height 532px

	&__inner
		padding-top 56.25%

	&_started:not(&_paused) &__overlay
		opacity 0

		&:hover
			opacity 1

			@media mobile
				opacity 0


	&_started &
		&__icon_play
			display none

		&__icon_pause
			display block

	&_paused &
		&__overlay
			opacity 1

		&__icon_pause
			display none

		&__icon_play
			display block

	&__overlay
		position absolute
		top 0
		left 0
		size 100%
		display flex
		justify-content center
		align-items center
		color white
		cursor pointer
		transition opacity trDur trFunc
		will-change opacity

		// тень
		&::before
			position absolute
			top 0
			left 0
			size 100%
			content ''
			background-image linear-gradient(184deg, rgba(black, 0), black)

	&__icon
		position relative
		size 74px

		&_pause
			display none

	&__player
		position absolute
		top 0
		left 0
		size 100%
