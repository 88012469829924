.footer
	display block
	color white
	background-color charcoal

	&__wrapper
		padding-y 16px
		display flex
		justify-content space-between
		align-items center

		@media tablet
			padding-bottom 15px

		@media mobile
			padding-top 30px
			padding-bottom 24px
			flex-direction column-reverse

	&__copyright
		@extends .text15Regular
		color rgba(white, .5)

		@media tablet
			display none
			font-size 14px
			color inherit

		@media mobile
			margin-bottom 10px
			display block

		&_tablet
			padding-top 20px
			padding-bottom 21px
			display none
			text-align center
			border-top 1px solid rgba(white, .4)

			@media tablet
				display block

			@media mobile
				display none

	&__link
		@extends .text15Medium
		text-decoration none
		cursor pointer
		color inherit
		transition color trDur trFunc
		will-change color

		@media mobile
			font-size 14px
			order -1

		&:hover,
		&:focus,
		&:active
			color darken(white, 15%)

	&__controls
		display flex
		align-items center

	&__social
		margin-left 50px
		display flex

		@media tablet
			margin-left 26px

		@media mobile
			margin-bottom 15px
			margin-left 0

	&__socialLink
		padding 5px
		display inline-block
		size 28px
		text-decoration none
		cursor pointer
		color inherit
		transition color trDur trFunc
		will-change color

		&:hover,
		&:focus,
		&:active
			color darken(white, 15%)

	&__socialItem
		line-height 0
		opacity .6

		&:not(:last-child)
			margin-right 15px

			@media tablet
				margin-right 24px

	&__lang
		@media mobile
			display none
