.partner
	padding-x 20px
	padding-top 70px
	padding-bottom 30px
	display block
	width 100%
	height 100%
	text-align center
	text-decoration none
	color black
	background-color whiteSmoke
	transition-duration trDur
	transition-timing-function trFunc
	transition-property color, background-color, transform
	will-change color, background-color, transform

	&:hover,
	&:focus
		color white
		background-color green
		transform translateY(-5px)

	&:hover::after,
	&:focus::after
		opacity 1

	// тень при ховере
	&::after
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		pointer-events none
		content ''
		opacity 0
		box-shadow 0 9px 16px 0 rgba(black, .08)
		transition opacity trDur trFunc
		will-change opacity

	&__logo
		display flex
		justify-content center
		align-items center
		height 145px

	&__logo-image
		width auto
		max-width 100%
		height auto
		max-height 100%

	&__title
		@extends .text22Medium
		margin-x auto
		display block
		margin-top 50px
		max-width 300px

	&__text
		@extends .text18Regular
		display block
		margin-x auto
		margin-top 13px
		max-width 300px
