.profile-offers
	display flex
	flex-direction column
	align-items center

	&__list
		margin-bottom 40px
		display flex
		justify-content space-between
		flex-wrap wrap
		width 100%
		color white

		@media tablet
			margin-bottom 20px
			flex-direction column
			align-items center

		@media mobile
			margin-bottom 24px

	&__item
		margin-bottom 30px
		width 458px
		height 400px

		@media tablet
			width 708px

		@media mobile
			margin-bottom 16px
			width 100%
			max-width 343px
