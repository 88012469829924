.event-card
	@extends .text18Regular
	position relative
	padding 25px 20px
	display flex
	flex-direction column
	min-height 100%
	text-decoration none
	color inherit
	background-color whiteSmoke

	@media tablet
		padding 32px 30px

	@media mobile
		padding 30px 20px

	&:hover::after
		opacity 1

	// тень при ховере
	&::after
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		content ''
		opacity 0
		box-shadow 0 9px 16px 0 rgba(black, .08)
		transition opacity trDur trFunc
		will-change opacity

	&_slider
		transition transform trDur trFunc
		will-change transform

		&:hover
			transform translateY(-5px)

	&__image
		margin -25px -20px 25px
		width calc(100% + 40px)
		height auto

		@media tablet
			margin -32px -30px 30px
			width calc(100% + 60px)

		@media mobile
			margin -30px -20px 30px
			width calc(100% + 40px)

	&__date
		@extends .text15Medium
		margin-bottom 4px

		@media tablet
			margin-bottom 6px

	&__title
		margin-bottom 15px

	&__text
		position relative
		margin-top auto
		overflow hidden
		max-height 78px

		@media tablet
			font-size 18px
