.slider-square
	position relative
	display flex
	flex-direction column
	align-items flex-end
	size 100%

	&__slide
		@media mobile
			position relative
			display flex
			justify-content center
			overflow hidden
			width 329px
			height 378px

	&__video
		position absolute
		top 50%
		left 0
		width 100%
		transform translateY(-50%)

	&__img
		size 100%
		background-position center center
		background-size cover

	&__wrapper
		flex-shrink 0

	&__controls
		position absolute
		z-index 2
		right 0
		bottom 0
		padding 10px 19px
		display flex
		user-select none
		background-color whiteSmoke
		align-items center
		justify-content center
		flex-shrink 0

		@media mobile
			display none

	&__pagination
		@extends .text18Regular
		position static
		margin-x 22px
		margin-y 0
		width auto
		color rgba(black, .4)

		.swiper-pagination-current
			color black

	&__button
		@extends .defaultHover
		position relative
		top -1px
		right 0
		left 0
		margin 0
		width 10px
		height 14px
		background url('/images/arrowLeft.svg') 50% 50% no-repeat
		background-size contain

		&_next
			transform rotate(180deg)
