.avatar
	display flex
	flex-direction column
	align-items center

	&__image-wrap
		margin-bottom 15px
		display flex
		justify-content center
		align-items center
		overflow hidden
		width 145px
		height 145px
		background-color whiteSmoke
		border-radius 50%

	&__image
		size 100%

	&__link
		@media mobile
			font-size 18px

	&__error
		@extends .text15Medium
		max-width 64px
		line-height 1
		text-align center
		color rgba(black, .18)

	&__loading
		width 29px
		height 32px
		color rgba(black, .1)
