@font-face
	font-family 'AvenirNext'
	src url('../../fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
		url('../../fonts/AvenirNextCyr-Regular.ttf') format('truetype'),
		url('../../fonts/AvenirNextCyr-Regular.woff') format('woff')
	font-weight normal
	font-style normal

@font-face
	font-family 'AvenirNext'
	src url('../../fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
		url('../../fonts/AvenirNextCyr-Medium.ttf') format('truetype'),
		url('../../fonts/AvenirNextCyr-Medium.woff') format('woff')
	font-weight 500
	font-style normal

@font-face
	font-family 'AvenirNext'
	src url('../../fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
		url('../../fonts/AvenirNextCyr-Demi.ttf') format('truetype'),
		url('../../fonts/AvenirNextCyr-Demi.woff') format('woff')
	font-weight 600
	font-style normal

@font-face
	font-family 'CormorantGaramond'
	src url('../../fonts/CormorantGaramond/CormorantGaramond-SemiBoldItalic.woff2') format('woff2'),
		url('../../fonts/CormorantGaramond/CormorantGaramond-SemiBoldItalic.ttf') format('truetype'),
		url('../../fonts/CormorantGaramond/CormorantGaramond-SemiBoldItalic.woff') format('woff')
	font-weight 700
	font-style italic
