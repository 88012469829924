.reserve-popup
	display flex
	flex-direction column
	align-items center

	&__down-text
		@extends .text15Medium
		width 100%
		position relative
		margin-top 30px
		text-align center

		&::after,
		&::before
			position absolute
			content ''
			left -30px
			width calc(100% + 60px)

			@media tablet
				left -40px
				width calc(100% + 80px)

			@media mobile
				left -16px
				width calc(100% + 32px)

		&::after
			top -30px
			height 1px
			background-color rgba(black, .15)

		&::before
			top -79px
			height 50px
			background-image linear-gradient(to bottom, rgba(white, 0), white)

	&__text
		@extends .text15Regular
		overflow-y auto
		margin-top 30px
		padding-top 30px
		width 100%
		max-height 230px
		border-top 1px solid rgba(black, .15)

		@media mobile
			padding-top 20px
			margin-top 50px

		// fix bug padding in firefox
		&::after
			display block
			content 'CSSSR'
			height 30px
			visibility hidden

			@media mobile
				height 20px

	&__name
		@extends .text15Medium

	&__post
		@extends .text15Regular
		font-size 12px

	&__avatar
		margin-bottom 15px
		width 60px
		height 60px
		border-radius 100%
