.profile-change
	display flex

	@media mobile
		flex-direction column
		text-align center
		align-items center

	&__section
		margin-left 50px
		display flex
		flex-direction column
		width 100%

		@media mobile
			margin-top 30px
			margin-left 0

	&__block
		padding-y 40px
		display flex
		flex-direction column

		@media mobile
			padding-y 30px
			align-items center

		&:first-child
			padding-top 30px

			@media tablet
				padding-top 0

		&:last-child
			padding-bottom 0

		&:not(:last-child)
			border-bottom 1px solid rgba(black, .25)

	&__title
		margin-bottom 16px

	&__text
		@extends .text18Medium
		width 100%

	&__form
		display flex
		flex-direction column
		width 294px

		@media mobile
			align-items center

	&__button
		margin-top 30px
		align-self flex-start

		@media mobile
			margin-top 20px
			align-self center
