.premium-page
	display block

	&__features-list
		margin-top 70px

		@media tablet
			margin-top 30px

		@media mobile
			margin-top 0

	&__feature
		margin-bottom 160px

		@media tablet
			margin-bottom 100px
			display flex
			flex-direction column
			align-items center
			width 100%

		@media mobile
			margin-bottom 30px

	&__pictures
		@media mobile
			display none

	&__slider
		display none
		width 100%

		@media mobile
			display block

	&__description
		margin-top 60px
		width 100%

		@media tablet
			margin-top 50px

		@media mobile
			margin-top 0
