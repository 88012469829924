.pictures-block
	display flex

	&__big
		size 555px
		background-position center center
		background-size cover

		@media tablet
			size 344px

	&__images-wrapper
		width 555px
		margin-left 30px
		display flex

		@media tablet
			width 344px
			margin-left 18px

	&__small
		background-position center center
		background-size cover

	&_theme_horizantal &__images-wrapper
		flex-direction column

	&_theme_horizantal &__small
			width 555px
			height 262px

			@media tablet
				width 344px
				height 163px

			&:last-child
				margin-top 30px

				@media tablet
					margin-top 18px

	&_theme_vertical &__images-wrapper
		flex-direction row

	&_theme_vertical &__small
		width 262px
		height 555px

		@media tablet
			width 163px
			height 344px

		&:last-child
			margin-left 30px

			@media tablet
				margin-left 18px
